import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Table,
  Upload,
  
} from "antd";
import React, { useEffect, useState } from "react";


import {

  requestCreateWheel,
  requestGetSaleNotification,
  requestUpdateSaleNotification,
  requestWheel,
} from "../../service/network/Api";
import { Header } from "../dashboard/component/Header";
import TextArea from "antd/lib/input/TextArea";
import swal from "sweetalert";



const SaleNotification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [listWhell, setListWhell] = useState('');
  const [visible, setVisible] = useState(0);
  const [updatedData, setUpdatedData] = useState('');


  const getData = async () => {
    setIsLoading(true);
    
    try {
      const res = await requestGetSaleNotification();
      if (res) {
        console.log("res", res);
        setIsLoading(false);
        const dataWell = res.description
        setListWhell(dataWell);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  const updateWheel = async () => {
    
    try {
      const pyload = {
        description: listWhell
      }
      const res = await requestUpdateSaleNotification(pyload);
      if (res) {
        console.log("res", res);
        setIsLoading(false);
        const dataWell = res.description
        setListWhell(dataWell);
      }
    } catch (error) {}
  };

  


  
  const handleUpdate = async() =>  {
    const lines = listWhell.trim().split('\n'); // Tách chuỗi thành mảng các dòng
    
    // if (lines.length !== 5) {
    //     // Nếu số lượng dòng không đúng, hiển thị thông báo lỗi
    //    swal({
    //     title: "Vui lòng cập nhật đúng 5 dòng! Số dòng hiện tại là: "+lines.length,
    //     icon: "error",});
    //     return;
    // }else{
      await updateWheel()
      swal({
        title: "Cập nhật thành công!",
        icon: "success",
      })
    
    // Nếu số lượng dòng đúng, thực hiện các thao tác cập nhật dữ liệu tiếp theo
  }

  return (
    <div style={{ marginTop: 10, marginLeft: 10 }}>
      <PageHeader
        title="Thông báo khuyến mãi"
        style={{ backgroundColor: "white", margin: "5px 10px 10px" }}
        extra={[
          <Header
            // showButton={true}
            onClick={() => {
              setVisible(2);
            }}

          />,
        ]}
      />
      <TextArea 
        rows={6}
        style={{
          fontSize: 20,
        }}
        
        onChange={(e) => setListWhell(e.target.value)}
        value={listWhell}/>
      <span style={{ fontSize: 15, textDecoration: "italic" }}> <span style={{ color: "red" }}>(*)</span>Quy tắc nội dung vòng quay. Dữ liệu khuyến mã sẽ được hiển thị tại trang home</span>
      <br />
      <Button 
        onClick={handleUpdate}
        style={{ marginTop: 20, fontSize: 15 }}
        type="primary"
      >
        Cập nhật
      </Button>
      {/* {renderModal()} */}
    </div>
  );
};

export default SaleNotification;
